import { template as template_f6e6d9e176c44792a2d2621f97717e47 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_f6e6d9e176c44792a2d2621f97717e47(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
