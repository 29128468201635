import { template as template_c6a37f794f3b473895d9a45dcf6f9734 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_c6a37f794f3b473895d9a45dcf6f9734(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
